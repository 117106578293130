<template lang="pug">
nuxt-layout
	nuxt-loading-indicator(:color="current.colors?.primary" )
	nuxt-route-announcer
	nuxt-page

	lazy-google-analytics(v-if='ui.policy.cookie.analytics')
	lazy-pwa

	client-only
		lazy-policy-cookie
		lazy-policy-privacy
</template>

<script setup lang="ts">
const { current } = useTheme()
const ui = useUiStore()
useOnlineNotification()

const head = useLocaleHead()
await useMetatags(head)
</script>

<style lang="scss">
@use '@/assets/scss/styles';
@use '@/assets/scss/background';
@use '@/assets/scss/vuetify';
@use '@/assets/scss/typography';
@use '@/assets/scss/motion'
</style>
