import { capitalize } from 'vue-demi'
import type { I18nHeadMetaInfo } from '@nuxtjs/i18n'

export const useMetatags = async (head: Ref<I18nHeadMetaInfo>) => {
	const app = useAppConfig()

	// const { theme } = useVuetify()
	const { t } = useNuxtI18n()

	const { origin } = useRequestURL()
	const title = capitalize(app.name)

	// Seo
	useSeoMeta({
		title,
		description: () => t('app.description'),
		ogTitle: title,
		ogDescription: () => t('app.description'),
		ogImage: `${origin}/icons/favicon%20-%20adaptative.svg`,
		ogImageAlt: title,
		twitterTitle: title,
		twitterDescription: () => t('app.description'),
		twitterImage: `${origin}/icons/favicon%20-%20adaptative.svg`,
		twitterCard: 'summary',
	})

	// Head
	return useHead({
		htmlAttrs: {
			...head.value?.htmlAttrs,
		},
		meta: [
			...(head.value?.meta || []),
		],
		link: [
			{ rel: 'icon', type: 'image/png', href: '/icons/favicon.png' },
			...(head.value?.link || []),
		],
	})
}
